import {Box, Typography} from '@mui/material';
import React, {memo} from 'react';

const DefaultIntro = () => {
    return (
        <Box sx={{mb: {xs: 4, md: 6}}}>
            <Typography variant="fancy_h3" component="h1" sx={{mb: 1.5}}>
                Configuration de votre projet
            </Typography>
            <Typography variant="subtitle2" component="p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.
            </Typography>
        </Box>
    );
};

export default memo(DefaultIntro);
